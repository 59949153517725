import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import TagListing from "../components/Language/TagListing";
import Layout from "../layout";
import config from "../../data/SiteConfig";

export default function UsedForTemplate({ pageContext, data }) {
  const { usedFor } = pageContext;
  const languageEdges = data.allLanguagesJson.edges;
  return (
    <Layout>
      <Helmet
        title={`Languages with "${usedFor}" getting help | ${config.siteTitle}`}
      />
      <TagListing
        tag={usedFor}
        category="Used For"
        allLanguagesJson={data.allLanguagesJson}
        languageEdges={languageEdges}
        description="Some languages are geared towards specific purposes, while others have a wider range of applications. Depending on what you want to build, there may be several options to choose from."
      />
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query UsedForPage($usedFor: String) {
    allLanguagesJson(limit: 1000, filter: { used_for: { in: [$usedFor] } }) {
      totalCount
      edges {
        node {
          slug
          name
          description
          used_for
          getting_help {
            value
          }
          pay {
            value
          }
          barrier_to_entry {
            value
          }
          demand {
            value
          }
        }
      }
    }
  }
`;
